import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import HeaderBar from "./HeaderBar";
import PlayerList from "./Admin/PlayerMaster/PlayerList";
import Settings from "./Settings";
import StaticPages from "./StaticPages";
import BannerMaster from './BannerMaster';
import AvatarMaster from "./AvatarMaster";
import WithdrawRequest from "./WithdrawRequest";
import WithdrawHistory from "./WithdrawHistory";
import WalletDetails from "./Common/WalletDetails";
import AdminGameSession from "./AdminGameSession";
import { callGetApi, callPostApi } from "../api/ApiCaller";
import { Pre_Launch_Data_GET } from "../api/ApiConst";
import LudoGame from "./games/ludo/LudoGame";
import SnakeLadderAssets from "./games/snakeLadder/SnakeLadderAssets";
import SnakeLadderGameTemplate from "./games/snakeLadder/SnakeLadderGameTemplate";
import SnakeLadderRunningGame from "./games/snakeLadder/SnakeLadderRunningGame";
import SnakeLadderTournament from "./games/snakeLadder/SnakeLadderTournament";
import DepositHistory from "./DepositHistory";
import PokerGameTemplate from "./games/poker/PokerGameTemplate";
import PokerRunningGame from "./games/poker/PokerRunningGame";
import PokerTournament from "./games/poker/PokerTournament";
import PokerStake from "./games/poker/PokerStake";
import PokerTurnTime from "./games/poker/PokerTurnTime";
import Home from "./Home";
import useWidowResize from "../customHooks/useWidowResize";
import LoginLog from "./LoginLog";
import Cookies from "universal-cookie";
import AgentPlayerListMaster from "./Agent/AgentPlayer/AgentPlayerListMaster";
import AgentPlayerDetails from "./Agent/AgentPlayer/AgentPlayerDetails";
import AgentDetails from "./Admin/AgentMaster/AgentDetails";
import AgentMaster from "./Admin/AgentMaster/AgentMaster";
import PlayerDetails from "./Admin/PlayerMaster/PlayerDetails";
import AgentVoucherMaster from "./Agent/VoucherMaster/AgentVoucherMaster";
import AdminVoucherMaster from "./Admin/VoucherMaster/AdminVoucherMaster";
import SuperAdminSettings from "./SuperAdmin/SuperAdminSettings";
import AdminReportMaster from "./AdminReportMaster";
import moduleUtilities from "../utilities/ModuleUtils";
import AdminPlayerCountReport from "./AdminPlayerCountReport";
import AdminStoreProduct from "./StoreProduct";
import AdminPokerGameSessionHistory from "./games/poker/AdminPokerGameSessionHistory";
import AdminCouponMaster from "./AdminCouponMaster";
import AdminSystemMessage from "./SystemMessage";
import AdminLuckyWheel from "./AdminLuckyWheel";
import PokerGame from "./games/poker/PokerGame";
import AgentWalletMaster from "./Agent/WalletMaster/AgentWallet";
import CommissionMaster from "./Agent/CommissionMaster/CommissionMaster";
import AdminBannedStatus from "./AdminBannedStatus";
import QuestionAnswerMaster from "./QuestionAnswer";

function useQuery() {
    const { search } = useLocation();
    const path = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Navigation = ({ pathName }) => {
    const cookies = new Cookies();
    const loginData = cookies.get("loginData") && cookies.get("loginData") !== undefined ? cookies.get("loginData") : null;
    const [active, setActive] = useState("");
    let query = useQuery();
    let { demo } = useParams();
    const [moduleList, setModuleList] = useState([])
    const [windowWidth, windowHeight] = useWidowResize();

    const handleOnchange = (data) => {
        setActive(data);
        if (windowWidth <= 992) {
            const body = document.body;
            if (body.classList.contains("sidebar-enable")) {
                body.classList.remove("sidebar-enable");
            } else {
                body.classList.remove("sidebar-enable");
            }
        }
    };

    useEffect(() => {
        callPostApi(
            Pre_Launch_Data_GET,
            loginData.accessToken,
            {},
            (response) => {
                setModuleList(response.responsePacket.beanSuperAdminSettingsList);
            },
            (error) => {
            }
        );
    }, []);
    return (
        <React.Fragment>
            <HeaderBar />
            <div className="main-content">
                <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<Home loginData={loginData} moduleList={moduleList} />} />
                    {(loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN") &&
                        <>
                            <Route path="/players" element={<PlayerList moduleList={moduleList} />} />
                            <Route path="/players/:filterType" element={<PlayerList moduleList={moduleList} />} />
                            <Route path="/avatar-master" element={<AvatarMaster />} />
                            <Route path="/banned-states" element={<AdminBannedStatus />} />
                            <Route path="/withdraw-request" element={<WithdrawRequest />} />
                            <Route path="/withdraw-request/:filterType" element={<WithdrawRequest />} />
                            <Route path="/withdraw-history" element={<WithdrawHistory />} />
                            <Route path="/withdraw-history/:filterType" element={<WithdrawHistory />} />
                            <Route path="/deposit-history" element={<DepositHistory />} />
                            <Route path="/deposit-history/:filterType" element={<DepositHistory />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/static-pages" element={<StaticPages />} />
                            <Route path="/login-logs" element={<LoginLog />} />

                            {
                                loginData?.userType === "ROLE_SUPER_ADMIN" &&
                                <Route path="/super/admin/settings" element={<SuperAdminSettings />} />
                            }
                            {
                                moduleUtilities.checkModuleIsEnable(moduleList, "BannerModule") &&
                                <Route path='/ad-banner-master' element={<BannerMaster />} />
                            }
                                <Route path="/session-history" element={<AdminGameSession />} />
                            {
                                moduleUtilities.checkModuleIsEnable(moduleList, "VoucherModule") &&
                                <Route path="/admin/voucher" element={<AdminVoucherMaster />} />
                            }
                            {
                                (moduleUtilities.checkModuleIsEnable(moduleList, "ReportModule") || loginData?.userType === "ROLE_SUPER_ADMIN") &&
                                <React.Fragment>
                                    <Route path="/report/chart" element={<AdminReportMaster moduleList={moduleList} />} />
                                    <Route path="/report/count" element={<AdminPlayerCountReport />} />
                                </React.Fragment>
                            }
                            {
                                (moduleUtilities.checkModuleIsEnable(moduleList, "SystemMessageModule")) &&
                                <Route path="/system-message" element={<AdminSystemMessage />} />
                            }
                                <React.Fragment>
                                    <Route path="/ludo-game-config" element={<LudoGame />} />
                                </React.Fragment>

                            <Route path="/walletDetails" element={<WalletDetails />} />
                            <Route path="/question-answer" element={<QuestionAnswerMaster />} />
                            <Route path="/playerDetail" element={<PlayerDetails rId={query.get("rId")} moduleList={moduleList} />} />
                        </>
                    }
                    {loginData?.userType === "ROLE_AGENT" &&
                        <>
                            <Route path="/agent/players" element={<AgentPlayerListMaster />} />
                            <Route path="/agent/playerDetail" element={<AgentPlayerDetails rId={query.get("rId")} moduleList={moduleList} />} />
                            {
                                moduleUtilities.checkModuleIsEnable(moduleList, "VoucherModule") &&
                                <Route path="/agent/voucher" element={<AgentVoucherMaster />} />

                            }

                            <Route path="/agent/walletDetails" element={<AgentWalletMaster />} />
                            <Route path="/agent/CommissionDetails" element={<CommissionMaster />} />

                        </>
                    }
                </Routes>
            </div>

            <React.Fragment>
                <div className="vertical-menu">
                    <div data-simplebar="init" className="h-100">
                        <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer" />
                            </div>
                            <div className="simplebar-mask">
                                <div className="simplebar-offset" style={{ right: "-16.8px", bottom: "0px" }}>
                                    <div className="simplebar-content-wrapper" style={{ height: "100%", overflow: "hidden scroll" }}>
                                        <div className="simplebar-content" style={{ padding: "0px" }}>
                                            {/*- Sidemenu */}
                                            <div id="sidebar-menu" className="mm-active">
                                                {/* Left Menu Start */}
                                                <ul className="metismenu list-unstyled mm-show" id="side-menu">
                                                    <li className="menu-title" key="t-menu">
                                                        Menu
                                                    </li>
                                                    <li>
                                                        <Link to="/" className={active === "" ? "waves-effect mm-active" : "waves-effect"} onClick={() => handleOnchange("")}>
                                                            <i className="fa fa-home" />
                                                            <span key="t-dashboards">Dashboard</span>
                                                        </Link>
                                                    </li>
                                                    {loginData?.userType === "ROLE_ADMIN" || loginData?.userType === "ROLE_SUPER_ADMIN" ?
                                                        <>
                                                            <li>
                                                                <Link to="/players" className={active === "player" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("player")}>
                                                                    <i className="fa fa-users" />
                                                                    <span key="t-dashboards">Player Master</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                moduleUtilities.checkModuleIsEnable(moduleList, "AgentModule") &&
                                                                <li>
                                                                    <Link to="/agent" className={active === "agent" ? "waves-effect mm-active" : "waves-effect"} onClick={() => handleOnchange("agent")}>
                                                                        <i className="fa fa-user" />
                                                                        <span key="t-dashboards">Agent Master</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {loginData?.userType === "ROLE_SUPER_ADMIN" &&
                                                                <li>
                                                                    <Link to="/super/admin/settings" className={active === "superAdminSetting" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("superAdminSetting")}>
                                                                        <i className="fa fa-cogs" />
                                                                        <span key="t-dashboards">Super Admin Setting</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            <li>
                                                                <Link to="/settings" className={active === "settings" ? "waves-effect mm-active" : "waves-effect"} onClick={() => handleOnchange("settings")}>
                                                                    <i className="fa fa-cog" />
                                                                    <span key="t-dashboards">Settings</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                moduleUtilities.checkModuleIsEnable(moduleList, "BannerModule") &&
                                                                <li>
                                                                    <Link
                                                                        to='ad-banner-master'
                                                                        className={active === 'banner-master' ? 'waves-effect mm-active' : 'waves-effect'}
                                                                        onClick={() => handleOnchange('banner-master')}
                                                                    >
                                                                        <i className='fa fa-image' />
                                                                        <span key='t-dashboards'>Banner Master</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            <li>
                                                                <Link
                                                                    to="/avatar-master"
                                                                    className={active === "avatar-master" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("avatar-master")}
                                                                >
                                                                    <i className="fa fa-user-circle" />
                                                                    <span key="t-dashboards">Avatar Master</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/question-answer"
                                                                    className={active === "question-answer" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("question-answer")}
                                                                >
                                                                    <i className="fa fa-user-circle" />
                                                                    <span key="t-dashboards">Question Answer</span>
                                                                </Link>
                                                            </li>
                                                            {(moduleUtilities.checkModuleIsEnable(moduleList, "LuckyWheelModule")) && <li>
                                                                <Link
                                                                    to="/lucky-wheel"
                                                                    className={active === "lucky-wheel" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("lucky-wheel")}
                                                                >
                                                                    <i class="fa fa-life-ring" aria-hidden="true"></i>
                                                                    <span key="t-dashboards">Lucky Wheel</span>
                                                                </Link>
                                                            </li>}
                                                            {
                                                                (moduleUtilities.checkModuleIsEnable(moduleList, "SystemMessageModule")) &&
                                                                <li>
                                                                    <Link
                                                                        to="/system-message"
                                                                        className={active === "system-message" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("system-message")}
                                                                    >
                                                                        <i class="fa fa-comments" aria-hidden="true"></i>
                                                                        <span key="t-dashboards">System Message</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {/*{
                                                                (moduleUtilities.checkModuleIsEnable(moduleList, "StoreModule") || loginData?.userType === "ROLE_SUPER_ADMIN") &&
                                                                <li>
                                                                    <Link
                                                                        to="/store-product"
                                                                        className={active === "store-product" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("store-product")}
                                                                    >
                                                                        <i className="fa fa-credit-card" />
                                                                        <span key="t-dashboards">Store Product</span>
                                                                    </Link>
                                                                </li>
                                                            } */}
                                                            {/* {
                                                                moduleUtilities.checkModuleIsEnable(moduleList, "CouponCodeModule") && <li>
                                                                    <Link
                                                                        to="/coupon-master"
                                                                        className={active === "coupon-master" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("coupon-master")}
                                                                    >
                                                                        <i className="fa fa-gift" />
                                                                        <span key="t-dashboards">Coupon Master</span>
                                                                    </Link>
                                                                </li>
                                                            } */}
                                                            {/* <li>
                                                                <a href="#" className="has-arrow waves-effect">
                                                                    <i className="fa fa-cloud" />
                                                                    <span key="t-ecommerce">Withdraw</span>
                                                                </a>
                                                                <ul className="sub-menu" aria-expanded="false">
                                                                    <li>
                                                                        <Link
                                                                            to="/withdraw-request"
                                                                            className={active === "withdraw-request" ? "waves-effect mm-active" : "waves-effect"}
                                                                            onClick={() => handleOnchange("withdraw-request")}
                                                                        >
                                                                            Withdraw Request
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                            to="/withdraw-history"
                                                                            className={active === "withdraw-history" ? "waves-effect mm-active" : "waves-effect"}
                                                                            onClick={() => handleOnchange("withdraw-history")}
                                                                        >
                                                                            Withdraw History
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/deposit-history"
                                                                    className={active === "deposit-history" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("deposit-history")}
                                                                >
                                                                    <i className="fa fa-coins" />
                                                                    <span key="t-dashboards">Deposit History</span>
                                                                </Link>
                                                            </li> */}
                                                            <li>
                                                                <Link
                                                                    to="/walletDetails"
                                                                    className={active === "walletDetails" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("walletDetails")}
                                                                >
                                                                    <i className="fa fa-wallet" />
                                                                    <span key="t-dashboards">Wallet Details</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                (moduleUtilities.checkModuleIsEnable(moduleList, "ReportModule") || loginData?.userType === "ROLE_SUPER_ADMIN") &&
                                                                <li>
                                                                    <a href="#" className="has-arrow waves-effect">
                                                                        <i className="bx bx-file"></i>
                                                                        <span key="t-ecommerce">Report</span>
                                                                    </a>
                                                                    <ul className="sub-menu" aria-expanded="false">
                                                                        <li>
                                                                            <Link
                                                                                to="/report/chart"
                                                                                className={active === "report" ? "waves-effect mm-active" : "waves-effect"}
                                                                                key="t-products"
                                                                                onClick={() => handleOnchange("report")}
                                                                            >
                                                                                Chart Report
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link
                                                                                to="/report/count"
                                                                                className={active === "poker-turn-time" ? "waves-effect mm-active" : "waves-effect"}
                                                                                key="t-products"
                                                                                onClick={() => handleOnchange("report-filter")}
                                                                            >
                                                                                Count Filter
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            }
                                                            <React.Fragment>
                                                                    <React.Fragment>
                                                                        <li>
                                                                            <a href="#" className="has-arrow waves-effect">
                                                                                <i class='bx bx-joystick-button' ></i>
                                                                                <span key="t-ecommerce">Ludo</span>
                                                                            </a>
                                                                            <ul className="sub-menu" aria-expanded="false">
                                                                                <li>
                                                                                    <Link
                                                                                        to="/ludo-game-config"
                                                                                        className={active === "ludo-game-config" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        onClick={() => handleOnchange("ludo-game-config")}
                                                                                    >
                                                                                        <span key="t-dashboards">Game Config</span>
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link
                                                                                        to="/session-history"
                                                                                        className={active === "session-history" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        onClick={() => handleOnchange("session-history")}
                                                                                    >
                                                                                        <span key="t-dashboards">Sessions History</span>
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </React.Fragment>


                                                                {
                                                                    moduleUtilities.checkModuleIsEnable(moduleList, "SnakeLadderModule") &&
                                                                    <React.Fragment>
                                                                        <li>
                                                                            <a href="#" className="has-arrow waves-effect">
                                                                                <i class='bx bx-joystick' ></i>
                                                                                <span key="t-ecommerce">Snake &amp; Ladder</span>
                                                                            </a>
                                                                            <ul className="sub-menu" aria-expanded="false">
                                                                                <li>
                                                                                    <Link
                                                                                        to="snake-ladder-assets"
                                                                                        className={active === "snake-ladder-assets" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        key="t-products"
                                                                                        onClick={() => handleOnchange("snake-ladder-assets")}
                                                                                    >
                                                                                        Assets
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link
                                                                                        to="snake-ladder-game-template"
                                                                                        className={active === "snake-ladder-game-template" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        key="t-product-detail"
                                                                                        onClick={() => handleOnchange("snake-ladder-game-template")}
                                                                                    >
                                                                                        Template
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link
                                                                                        to="snake-ladder-running-game"
                                                                                        className={active === "snake-ladder-running-game" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        key="t-product-detail"
                                                                                        onClick={() => handleOnchange("snake-ladder-running-game")}
                                                                                    >
                                                                                        Running Game
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link
                                                                                        // to="snake-ladder-tournament"
                                                                                        className={active === "snake-ladder-tournament" ? "waves-effect mm-active" : "waves-effect"}
                                                                                        key="t-product-detail"
                                                                                        onClick={() => handleOnchange("snake-ladder-tournament")}
                                                                                    >
                                                                                        Tournament
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </React.Fragment>
                                                                }
                                                                {
                                                                    moduleUtilities.checkModuleIsEnable(moduleList, "PredictionModule") &&
                                                                    <React.Fragment>
                                                                        <li>
                                                                            <a href="#" className="has-arrow waves-effect">
                                                                                <i class='bx bx-joystick' ></i>
                                                                                <span key="t-ecommerce">Prediction</span>
                                                                            </a>
                                                                            <ul className="sub-menu" aria-expanded="false">
                                                                                <li>
                                                                                    <a>Assets</a>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            <li>
                                                                <Link
                                                                    to="/static-pages"
                                                                    className={active === "staticPages" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("staticPages")}
                                                                >
                                                                    <i className="bx bx-file" />
                                                                    <span key="t-dashboards">Static Pages</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    to="/login-logs"
                                                                    className={active === "loginLogs" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("loginLogs")}
                                                                >
                                                                    <i className="bx bx-log-in-circle" />
                                                                    <span key="t-dashboards">Login Logs</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                moduleUtilities.checkModuleIsEnable(moduleList, "VoucherModule") &&
                                                                <li>
                                                                    <Link to="/admin/voucher" className={active === "voucher" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("voucher")}>
                                                                        <i className="bx bxs-coupon" />
                                                                        <span key="t-dashboards">Voucher Master</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                        </> :
                                                        <>
                                                            <li>
                                                                <Link to="/agent/players" className={active === "agentPlayer" ? "waves-effect mm-active" : "waves-effect"} onClick={() => handleOnchange("agentPlayer")}>
                                                                    <i className="fa fa-users" />
                                                                    <span key="t-dashboards">Player Master</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                moduleUtilities.checkModuleIsEnable(moduleList, "VoucherModule") &&
                                                                <li>
                                                                    <Link to="/agent/voucher" className={active === "voucher" ? "waves-effect mm-active" : "waves-effect"}
                                                                        onClick={() => handleOnchange("voucher")}>
                                                                        <i className="bx bxs-coupon" />
                                                                        <span key="t-dashboards">Voucher Master</span>
                                                                    </Link>
                                                                </li>
                                                            }

                                                            <li>
                                                                <Link to="/agent/walletDetails" className={active === "wallet" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("wallet")}>
                                                                    <i className="fa fa-wallet" />
                                                                    <span key="t-dashboards">My Transaction</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/agent/CommissionDetails" className={active === "commission" ? "waves-effect mm-active" : "waves-effect"}
                                                                    onClick={() => handleOnchange("commission")}>
                                                                    <i className="fa fa-wallet" />
                                                                    <span key="t-dashboards">Commission</span>
                                                                </Link>
                                                            </li>

                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                            {/* Sidebar */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: "auto", height: "1322px" }} />
                        </div>
                        <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                            <div className="simplebar-scrollbar" style={{ transform: "translate3d(0px, 0px, 0px)", display: "none" }} />
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                            <div
                                className="simplebar-scrollbar"
                                style={{
                                    height: "61px",
                                    transform: "translate3d(0px, 0px, 0px)",
                                    display: "block",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default Navigation;
